import React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { getClosed } from '../../helper/setLocalStorage';


const MainDiv = ({ photo, name, location, collaboratorModal, onDelete, id, from, onEdit, item }) => {
  const url = 'https://icon-library.com/images/no-user-image-icon/no-user-image-icon-29.jpg';
  return (
    <div className={collaboratorModal ? 'AddedAdjCollab mb-3' : 'AddedAdj'}>
      <div className="leftDivAdj ">
        <div className="adjImg mb-1">
          <img src={photo ? photo : url} />
          <div className="adjDes">
            <span className="adjNAme">{name}</span>
            <span className="adjCoun">{location}</span>
          </div>
        </div>

      </div>
      {from != 'golive'&& (
        <div className='d-flex'>
          <div className="rightDivAdj px-0" onClick={() => onEdit(item, from)}><EditIcon sx={{ color: "#68AD3F !important" }} /></div>
          <div className="rightDivAdj px-0" onClick={() => { onDelete(id, from); }}><DeleteOutlineIcon /></div>
        </div>
      )}
    </div>
  );
};

export default MainDiv;
