import React, { useState, useEffect } from "react";
import { serviceConsumer } from "../../network/ServiceConsumer";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Container, ListGroup, ListGroupItem } from "react-bootstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import { getAddressObject } from "../../helper/getAddressObject";
import { venueDetailsValidationSchema } from "../../helper/Validations";
import {
  accessTillEventDetails,
  accessTillVenueDetails,
} from "../../helper/CreateDispatches";
import { v4 as uuidv4 } from "uuid";

const VenueFies = (props) => {
  const [loading, setLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState();
  const [showList, setShowList] = useState(false);
  const [value, setValue] = useState("");
  const [adress, setAddress] = useState();
  const [lat, setLat] = useState();
  const [lon, setLon] = useState();
  const dispatch = useDispatch();
  const previousEvent = localStorage.getItem("previousEvent");
  let EventID = localStorage.getItem("ID");
  const page = localStorage.getItem("page");
  const TAB = localStorage.getItem("TAB");

  useEffect(() => {
    dispatch({
      type: "ORG_TAB",
      payload: {
        Event_Details: true,
        Venue_Details: true,
      },
    });
    page > TAB && dispatchFunction();
    getEventDetails();
  }, []);

  useEffect(() => {
    setValue(eventDetails?.venue);
  }, [value]);

  //  to get eventDetails on back
  const getEventDetails = async () => {
    try {
      setLoading(true);
      if (EventID) {
        const url =
          props.type === "feis"
            ? `${process.env.REACT_APP_BASE_URL}/api/admin/feis/${EventID}`
            : `${process.env.REACT_APP_BASE_URL}/api/admin/grade/${EventID}`;
        const response = await serviceConsumer("GET", url);
        page > TAB &&
          localStorage.setItem(
            "page",
            props.type === "feis"
              ? response?.Feis?.page
              : response?.grade_exams?.page
          );
        setEventDetails(
          props.type === "feis" ? response.Feis : response.grade_exams
        );
        const res =
          props.type === "feis" ? response.Feis : response.grade_exams;
        setAddress({
          address: res?.venue,
          city: res?.venue_city,
          state: res?.state,
          country: res?.country,
          postal_code: res?.postal_code,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const venueDetailsValues = {
    venue_name: eventDetails?.venue_name,
    venue: eventDetails?.venue,
    city: eventDetails?.venue_city,
    state: eventDetails?.state,
    country: eventDetails?.country,
    postal_code: eventDetails?.postal_code,
  };

  const dispatchFunction = (next) => {
    next ? accessTillEventDetails(dispatch) : accessTillVenueDetails(dispatch);
  };

  // submitHandler
  const EventDetalsHandler = async (val) => {
    let value = {
      ...val,
      venue_city: val.city,
      page: localStorage.getItem("page") > 2 ? localStorage.getItem("page") : 2,
      latitude: lat,
      longitude: lon,
    };

    try {
      setLoading(true);
      const url =
        props.type === "feis"
          ? `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`
          : `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
      const response = await serviceConsumer("PUT", url, value);
      if (response.success === true) {
        props.setValue("3");
        localStorage.setItem("TAB", 3);
        if (Number(localStorage.getItem("page")) < 2) {
          localStorage.setItem("page", 2);
        }
        dispatchFunction(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_API,
    options: { componentRestrictions: { country: ["ca", "USA"] } },
  });

  const getDetails = (placeid, formik) => {
    formik.setFieldTouched("venue", false);
    formik.setFieldTouched("venue", false);
    const data = placePredictions.filter((item) => item.place_id == placeid);
    placesService?.getDetails(
      {
        placeId: data[0].place_id,
      },

      (placeDetails) => {
        setLat(placeDetails.geometry.location.lat().toString());
        setLon(placeDetails.geometry.location.lng().toString());
        const address = getAddressObject(placeDetails?.address_components);
        setValue(placeDetails?.name);
        const venueVal = address.home + " " + address.street;
        formik.setFieldValue("venue", venueVal);
        formik.setFieldValue("city", address?.city);
        formik.setFieldValue("state", address?.region);
        formik.setFieldValue("country", address?.country);
        formik.setFieldValue("postal_code", address?.postal_code);
        setAddress({
          address: placeDetails?.name,
          city: address?.city,
          province: address?.region,
          postalCode: address?.postal_code,
          lat: placeDetails.geometry.location.lat().toString(),
          lon: placeDetails.geometry.location.lng().toString(),
        });
        setShowList(false);
      }
    );
  };

  const getDisabled = (formik) => {
    const isValid = !!(
      formik.values.venue_name &&
      formik.values.venue &&
      formik.values.city &&
      formik.values.state &&
      formik.values.country &&
      formik.values.postal_code
    );
    return isValid;
  };
  const getButtonClass = (formik) => {
    if (previousEvent) return "filled";
    else if (getDisabled(formik) && adress?.address) return "filled";
    else return "disabled";
  };

  const getErrorClass = (error, touched, errorClass, defaultClass) => {
    if (error && touched) return errorClass;
    else return defaultClass;
  };

  return (
    <Container className="px-0">
      {loading && <Loader />}
      {!loading && (
        <Formik
          initialValues={venueDetailsValues}
          validationSchema={venueDetailsValidationSchema}
          validateOnMount={true}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={EventDetalsHandler}
        >
          {(formik) => (
            <Form className="form form-label-right" autoComplete="off">
              <div>
                <div>
                  <div className="detailsHeaders">
                    <h4 className="eventPage">Venue Details</h4>
                    <p className="eventDescription">
                      Complete these details to create event.
                    </p>
                  </div>
                  <div className="d-flex flex-column ">
                    <div
                      className={getErrorClass(
                        formik.errors?.venue_name,
                        formik.touched?.venue_name,
                        "",
                        "input-control"
                      )}
                    >
                      <label
                        className={getErrorClass(
                          formik?.errors?.venue_name,
                          formik?.touched?.venue_name,
                          "label-head-org-error",
                          "label-head-org"
                        )}
                      >
                        Venue Name:
                      </label>
                      <br />
                      <Field
                        className={getErrorClass(
                          formik?.errors?.venue_name,
                          formik?.touched?.venue_name,
                          "input-box-org-error",
                          "input-box-org"
                        )}
                        type="text"
                        name="venue_name"
                        placeholder="Venue Name"
                      />
                      <br />
                      <ErrorMessage
                        className="auth-error"
                        name="venue_name"
                        component="div"
                      />
                    </div>
                    <div
                      className={getErrorClass(
                        !formik.values.venue,
                        formik.touched.venue,
                        "",
                        "input-control"
                      )}
                    >
                      <label
                        className={getErrorClass(
                          !formik.values?.venue,
                          formik?.touched?.venue,
                          "label-head-org-error",
                          "label-head-org"
                        )}
                      >
                        Address:{" "}
                      </label>
                      <div className="d-grid">
                        <Field
                          className={getErrorClass(
                            !formik.values?.venue,
                            formik?.touched?.venue,
                            "input-box-org-error textAreaError",
                            "input-box-org textArea"
                          )}
                          as="textarea"
                          name="venue"
                          placeholder="Address"
                          autoComplete="new-password"
                          onChange={(evt) => {
                            setValue(evt.target.value);
                            setAddress(null);
                            getPlacePredictions({ input: evt.target.value });
                            if (!isPlacePredictionsLoading) setShowList(true);
                            formik.setFieldValue("venue", evt.target.value); // Update the formik field value
                          }}
                        />
                        {value !== "" &&
                          showList &&
                          placePredictions.length > 0 && (
                            <div className="google-autosuggestion-list">
                              <ListGroup dataSource={placePredictions}>
                                {placePredictions.map((item) => (
                                  <ListGroupItem
                                    key={uuidv4()}
                                    onClick={() =>
                                      getDetails(item?.place_id, formik)
                                    }
                                  >
                                    {item.description}
                                  </ListGroupItem>
                                ))}
                              </ListGroup>
                            </div>
                          )}
                        <ErrorMessage
                          className="auth-error"
                          name="address"
                          component="div"
                        />
                      </div>
                      <ErrorMessage
                        className="auth-error"
                        name="venue"
                        component="div"
                      />{" "}
                    </div>
                    <div
                      className={getErrorClass(
                        !formik.values?.city,
                        formik.touched?.city,
                        "",
                        "input-control"
                      )}
                    >
                      <label
                        className={getErrorClass(
                          !formik.values?.city,
                          formik?.touched?.city,
                          "label-head-org-error",
                          "label-head-org"
                        )}
                      >
                        City:{" "}
                      </label>
                      <br />
                      <Field
                        className={getErrorClass(
                          !formik.values?.city,
                          formik?.touched?.city,
                          "input-box-org-error",
                          "input-box-org"
                        )}
                        type="text"
                        name="city"
                        placeholder="City"
                      />
                      {!formik.values.city && (
                        <ErrorMessage
                          className="auth-error"
                          name="city"
                          component="div"
                        />
                      )}
                    </div>
                    <div
                      className={getErrorClass(
                        !formik.values?.state,
                        formik.touched?.state,
                        "",
                        "input-control"
                      )}
                    >
                      <label
                        className={getErrorClass(
                          !formik.values?.state,
                          formik?.touched?.state,
                          "label-head-org-error",
                          "label-head-org"
                        )}
                      >
                        State/Province:{" "}
                      </label>
                      <br />
                      <Field
                        className={getErrorClass(
                          !formik.values?.state,
                          formik?.touched?.state,
                          "input-box-org-error",
                          "input-box-org"
                        )}
                        type="text"
                        name="state"
                        placeholder="State/Province"
                        setFieldValue={formik.setFieldValue}
                      />
                      <br />
                      {!formik.values.state && (
                        <ErrorMessage
                          className="auth-error"
                          name="state"
                          component="div"
                        />
                      )}
                    </div>
                    <div
                      className={getErrorClass(
                        !formik.values?.country,
                        formik.touched?.country,
                        "",
                        "input-control"
                      )}
                    >
                      <label
                        className={getErrorClass(
                          !formik.values?.country,
                          formik?.touched?.country,
                          "label-head-org-error",
                          "label-head-org"
                        )}
                      >
                        Country:{" "}
                      </label>
                      <br />
                      <Field
                        className={getErrorClass(
                          !formik.values?.country,
                          formik?.touched?.country,
                          "input-box-org-error",
                          "input-box-org"
                        )}
                        type="text"
                        name="country"
                        placeholder="Country"
                      />
                      <br />
                      {!formik.values.country && (
                        <ErrorMessage
                          className="auth-error"
                          name="country"
                          component="div"
                        />
                      )}
                    </div>
                    <div
                      className={getErrorClass(
                        !formik.values?.postal_code,
                        formik.touched?.postal_code,
                        "",
                        "input-control"
                      )}
                    >
                      <label
                        className={getErrorClass(
                          !formik.values?.postal_code,
                          formik?.touched?.postal_code,
                          "label-head-org-error",
                          "label-head-org"
                        )}
                      >
                        Postal / Zip Code:
                      </label>
                      <br />
                      <Field
                        className={getErrorClass(
                          !formik.values?.postal_code,
                          formik?.touched?.postal_code,
                          "input-box-org-error",
                          "input-box-org"
                        )}
                        type="text"
                        name="postal_code"
                        placeholder="Postal / Zip Code"
                      />
                      <br />
                      {!formik.values.postal_code && (
                        <ErrorMessage
                          className="auth-error"
                          name="postal_code"
                          component="div"
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-3 mb-5">
                    <button className={getButtonClass(formik)} type="submit">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
};

export default VenueFies;
